<template>
  <div class="w-full absolute">
    <!-- Navigation starts -->

    <nav
      class="
        h-16
        flex
        items-center
        border-b-2 border-lightGrey
        lg:items-stretch
        justify-end
        lg:justify-between
        bg-white
        relative
        z-30
      "
    >
      <div class="hidden lg:flex w-full pr-6">
        <div class="w-1/2 h-full hidden lg:flex items-center pl-6 pr-24">
          <router-link :to="{ name: 'practice-select' }">
            <div class="h-16 w-full flex flex-1 items-center bg-white">
              <img
                class="w-56 h-11"
                src="@/assets/logos/rushden_horizontal.png"
                alt="Rushden Medical Centre Logo"
              />
            </div>
          </router-link>
        </div>
        <div class="w-1/2 hidden lg:flex">
          <div class="w-full flex items-center pl-8 justify-end">
            <!-- Navigation Bell Icon -->
            <div class="h-full w-20 flex items-center justify-center border-r">
              <div
                class="relative cursor-pointer text-grey hover:text-darkGrey"
              >
                <span class="material-icons"> notification_important </span>
                <div
                  class="
                    w-2
                    h-2
                    rounded-full
                    bg-red
                    border border-white
                    absolute
                    inset-0
                    mt-1
                    mr-1
                    m-auto
                  "
                ></div>
              </div>
            </div>
            <div
              class="flex items-center relative cursor-pointer"
              @click="dropdownHandler($event)"
              @focusout="handleFocusOut($event)"
              tabindex="0"
            >
              <div class="rounded-full">
                <ul
                  class="
                    p-2
                    w-full
                    border-r
                    bg-white
                    absolute
                    rounded
                    left-0
                    shadow
                    mt-6
                    sm:mt-10
                    hidden
                  "
                >
                  <li
                    class="
                      flex
                      w-full
                      justify-between
                      text-grey
                      hover:text-darkGrey
                      cursor-pointer
                      items-center
                    "
                  ></li>
                  <li
                    class="
                      flex
                      w-full
                      justify-between
                      text-grey
                      hover:text-darkGrey
                      cursor-pointer
                      items-center
                      mt-2
                    "
                    @click="logoutUser"
                  >
                    <div class="flex items-center">
                      <span class="material-icons"> logout </span>
                      <span class="text-sm ml-2">Sign out</span>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="pl-4">
                <div
                  class="
                    rounded-full
                    h-10
                    w-10
                    flex
                    items-center
                    justify-center
                  "
                >
                  <img
                    v-if="userName.profile.profile_image"
                    alt="Profile"
                    @error="setAltImg"
                    :src="userName.profile?.profile_image"
                    class="mx-auto object-cover rounded-full w-full h-full"
                  />
                  <img
                    v-else
                    alt="Profile"
                    src="../../../assets/1.png"
                    class="mx-auto object-cover rounded-full w-full h-full"
                  />
                </div>
              </div>
              <p class="text-darkGrey text-sm mx-3">
                Welcome Back
                <strong class="text-black">{{
                  userName.profile?.first_name
                }}</strong>
              </p>
              <div class="cursor-pointer text-darkGrey">
                <span class="material-icons"> arrow_drop_down </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Hamburger Toogle -->
      <div
        class="text-grey mr-8 visible lg:hidden relative cursor-pointer"
        @click="dropdownHandler($event)"
      >
        <ul
          class="
            p-2
            w-40
            border-r
            bg-white
            absolute
            rounded
            right-0
            shadow
            mt-12
            hidden
          "
        >
          <li
            class="
              flex
              w-full
              justify-between
              text-grey
              hover:text-darkGrey
              cursor-pointer
              items-center
            "
          >
            <div class="flex items-center">
              <router-link
                :to="{ name: 'ma-profile', query: { activeTab: 'profile' } }"
              >
                <span class="text-sm">My Profile</span>
              </router-link>
            </div>
          </li>
          <li
            class="
              flex
              w-full
              justify-between
              text-grey
              hover:text-darkGrey
              cursor-pointer
              items-center
              mt-2
            "
            @click="logoutUser"
          >
            <div class="flex items-center">
              <span class="text-sm">Sign out</span>
            </div>
          </li>
        </ul>
        <span class="material-icons"> menu </span>
      </div>
    </nav>
    <!-- Navigation ends -->
  </div>
</template>

<script>
export default {
  emits: ["user-logout"],
  props: {
    userName: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageError: false,
    };
  },
  methods: {
    setAltImg() {
      this.imageError = true;
    },
    dropdownHandler(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
    logoutUser() {
      this.$emit("user-logout");
    },
    handleFocusOut(event) {
      let single = event.currentTarget.getElementsByTagName("ul")[0];
      single.classList.toggle("hidden");
    },
  },
};
</script>
