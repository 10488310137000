<script setup>
import { onMounted, computed, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Sidebar from "@/components/layout/generalUser/sidebar.vue";
import Navbar from "@/components/layout/generalUser/navbar.vue";

const router = useRouter();
const store = useStore();

const toast = inject("toast");
onMounted(() => {
  store.commit(
    "profile/setSideBarPosition",
    JSON.parse(localStorage.getItem("sidebarOpen"))
  );
});
const userName = computed(() => {
  return store.getters["auth/userName"];
});
const initials = computed(() => {
  return localStorage.getItem("initials");
});

const logout = async () => {
  const tokenPayload = localStorage.getItem("token");
  try {
    await store.dispatch("auth/logout", tokenPayload);
    router.push("/login");
  } catch (err) {
    toast.show(err.message || "Something went wrong, try later.");
  }
};
</script>
<template>
  <Navbar :userName="userName" :userInitials="initials" @user-logout="logout" />
  <div class="flex flex-no-wrap h-screen bg-lightGrey pt-16 overflow-hidden">
    <section>
      <Sidebar />
    </section>
    <central-card>
      <slot></slot>
    </central-card>
  </div>
</template>
