<template>
  <!-- Sidebar starts -->
  <div class="block top-16" :class="{ 'w-16': sidebarOpen }" id="mobile-nav">
    <div
      class="
        bg-teal
        shadow
        overflow-scroll overflow-x-hidden
        transition-width transition-slowest
        ease
      "
      id="forHeight"
      :class="[sidebarOpen ? 'w-16' : 'w-64']"
    >
      <div
        id="openSideBar"
        class="
          h-10
          w-10
          absolute
          flex
          items-center
          justify-center
          cursor-pointer
        "
        :class="[sidebarOpen ? 'left-5' : 'left-52']"
        @click="sidebarHandler(true)"
      >
        <span v-if="!sidebarOpen" class="material-icons text-white">
          close
        </span>
        <span
          v-else
          class="text-white text-2xl material-icons transform rotate-180"
          >menu_open
        </span>
      </div>
      <div class="pt-14 text-white" :class="[sidebarOpen ? 'px-2' : 'px-8']">
        <div
          v-for="(category, index) in categories"
          :key="index"
          :class="{ 'pt-4': index > 0 }"
        >
          <div v-if="!sidebarOpen">
            <router-link :to="{ name: category.link }">
              <span
                @click="category.open = !category.open"
                class="
                  flex
                  w-full
                  cursor-pointer
                  items-center
                  mb-1
                  hover:text-darkGrey
                "
              >
                <div class="py-1 px-3 flex items-center justify-center text-xs">
                  <span class="material-icons"> {{ category.icon }} </span>
                </div>

                <div class="flex items-end">
                  <span
                    class="text-sm"
                    :class="[sidebarOpen ? 'hidden' : '']"
                    >{{ category.name }}</span
                  >
                </div>
              </span>
            </router-link>
          </div>
          <div v-else>
            <span
              @click="category.open = !category.open"
              class="
                flex
                w-full
                cursor-pointer
                items-center
                mb-1
                hover:text-darkGrey
              "
            >
              <router-link v-if="!category.query" :to="{ name: category.link }">
                <div class="py-1 px-3 flex items-center justify-center text-xs">
                  <span class="material-icons"> {{ category.icon }} </span>
                </div>
              </router-link>
              <router-link
                v-else
                :to="{
                  name: category.link,
                  query: { activeTab: category.query },
                }"
              >
                <div class="py-1 px-3 flex items-center justify-center text-xs">
                  <span class="material-icons"> {{ category.icon }} </span>
                </div>
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: this.categoryHandler(),
    };
  },
  methods: {
    sidebarHandler() {
      localStorage.setItem("sidebarOpen", !this.sidebarOpen);
      this.$store.commit("profile/setSideBarPosition", !this.sidebarOpen);
    },
    categoryHandler() {
      if (this.isLocum) {
        return {
          profile: {
            name: "Profile",
            icon: "account_circle",
            open: false,
            query: "profile",
            link: "us-details",
            paramName: "userId",
          },
          dashboard: {
            name: "Training Courses",
            icon: "dashboard",
            open: false,
            query: "profile",
            link: "general-user-dashboard",
          },
          complianceGroup: {
            name: "Locum Dashboard",
            icon: "assignment_turned_in",
            open: false,
            link: "us-locums",
            items: [{ name: "", link: "" }],
          },
          practiceFunctions: {
            name: "Practice Policies",
            icon: "functions",
            open: false,
            link: "user-practice-policies",
          },
        };
      } else {
        return {
          profile: {
            name: "Profile",
            icon: "account_circle",
            open: false,
            query: "profile",
            link: "us-details",
            paramName: "userId",
          },
          dashboard: {
            name: "Training Courses",
            icon: "dashboard",
            open: false,
            query: "profile",
            link: "general-user-dashboard",
          },
          practiceFunctions: {
            name: "Practice Policies",
            icon: "functions",
            open: false,
            link: "user-practice-policies",
          },
        };
      }
    },
  },
  computed: {
    sidebarOpen() {
      return this.$store.getters["profile/getSidbarOpen"];
    },
    isLocum() {
      return this.$store.getters["auth/userIsLocum"];
    },
  },
};
</script>

<style scoped>
a.router-link-active {
  color: #ffb81c;
}
#forHeight {
  height: calc(100vh - 64px);
}
</style>
